export default {
  wrQwwbSV: "中文",
  hGtEfNnp: "英语",
  "noPage.back": "Go Back",
  "queryMiner.aleoAddress": "Aleo mining wallet address",
  "queryMiner.hashrate": "Hashrate",
  "queryMiner.minerData": "Miner Data",
  "management.machine.online": "Online Worker",
  "management.machine.credits": "Credits",
  "queryMiner.inputAddress": "Please input your Aleo address",
  "queryMiner.reward": "Reward",
  "error.aleoFormat": "Aleo format error, please re-enter!",
};
