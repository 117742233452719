import { t } from "@/utils/i18n";
import { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { formatTime } from "../utils/time";

import type { ColumnsType } from "antd/es/table";

import aleoService, { MachineDTO } from "./service";
import { useRequest } from "@/hooks/use-request";

interface PropsType {
  address?: any;
  onClose?: () => void;
}

const MachinePage: React.FC<PropsType> = ({ address }) => {
  const [dataSource, setDataSource] = useState<MachineDTO[]>([]);
  const { loading, runAsync: machine } = useRequest(aleoService.machine, {
    manual: true,
  });

  const getList = async () => {
    const [error, data] = await machine(address);
    if (!error) {
      setDataSource(data);
    }
  };

  useEffect(() => {
    console.log("useEffect");
    getList();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: "NO.",
      render: (_, __, index) => `${index + 1}`,
    },

    {
      title: t("Machine Name"),
      dataIndex: "machine_name",
    },
    {
      title: t("IP"),
      dataIndex: "ip",
    },
    {
      title: t("MAC"),
      dataIndex: "mac",
    },
    {
      title: t("Hashrate (P/s)"),
      dataIndex: "speed",
      render: (_) => (+_).toFixed(4),
      sorter: (a, b) => a.speed - b.speed,
    },
    {
      title: t("State"),
      dataIndex: "push_time",
      render: (push_time) => {
        const online =
          push_time * 1000 > new Date().getTime() - 1 * 60 * 60 * 1000;
        return (
          <Tag color={online ? "#2db7f5" : "#f50"}>
            {online ? "Online" : "Offline"}
          </Tag>
        );
      },
      sorter: (a, b) => a.push_time - b.push_time,
    },
    {
      title: t("Push Time"),
      dataIndex: "push_time",
      render: (push_time) => formatTime(push_time * 1000),
    },
  ];

  return (
    <div>
      <div className="mt-[16px] dark:bg-[rgb(33,41,70)] bg-white rounded-lg px-[12px]">
        <Table
          rowKey="mac"
          scroll={{ x: true }}
          columns={columns}
          className="bg-transparent"
          loading={loading}
          dataSource={dataSource}
        />
      </div>
    </div>
  );
};

export default MachinePage;
