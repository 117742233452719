import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from "axios";
import { useGlobalStore } from "@/stores/global";
import { antdUtils } from "@/utils/antd";

export type Response<T> = Promise<[boolean, T, AxiosResponse<T>]>;

class Request {
  constructor(config?: CreateAxiosDefaults) {
    this.axiosInstance = axios.create(config);

    this.axiosInstance.interceptors.request.use(
      (axiosConfig: InternalAxiosRequestConfig) =>
        this.requestInterceptor(axiosConfig)
    );
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse<unknown, unknown>) =>
        this.responseSuccessInterceptor(response),
      (error: unknown) => this.responseErrorInterceptor(error)
    );
  }

  private axiosInstance: AxiosInstance;

  private requestingCount = 0;

  private async requestInterceptor(
    axiosConfig: InternalAxiosRequestConfig
  ): Promise<any> {
    this.requestingCount += 1;

    const { token } = useGlobalStore.getState();
    if (token) {
      axiosConfig.headers.Authorization = `Bearer ${token}`;
    }
    return Promise.resolve(axiosConfig);
  }

  private async responseSuccessInterceptor(
    response: AxiosResponse<any, any>
  ): Promise<any> {
    if (response.data.code != 200) {
      antdUtils.notification?.error({
        message: "error",
        description: response.data.detail[0].msg,
      });
      return Promise.resolve([true, response.data.detail]);
    }
    console.log("response.data.data:", response.data.data);
    return Promise.resolve([false, response.data.data, response]);
  }

  private async responseErrorInterceptor(error: any): Promise<any> {
    this.requestingCount -= 1;
    const { config, status } = error?.response || {};
    console.log(config, status);
    antdUtils.notification?.error({
      message: "出错了",
      description: error?.response?.data?.message,
    });
    return Promise.resolve([true, error?.response?.data]);
  }

  request<T, D = unknown>(config: AxiosRequestConfig<D>): Response<T> {
    return this.axiosInstance(config);
  }

  get<T, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Response<T> {
    return this.axiosInstance.get(url, config);
  }

  post<T, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Response<T> {
    return this.axiosInstance.post(url, data, config);
  }

  put<T, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Response<T> {
    return this.axiosInstance.put(url, data, config);
  }

  delete<T, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>
  ): Response<T> {
    return this.axiosInstance.delete(url, config);
  }
}

const request = new Request({ timeout: 60 * 1000 * 5 });

export default request;
