import { observer } from "mobx-react";
import { t } from "@/utils/i18n";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import { antdUtils } from "@/utils/antd";
import { SearchOutlined } from "@ant-design/icons";

const Search = () => {
  const navigate = useNavigate();

  const onSearch = () => {
    const value =
      (document.getElementById("inputSearch") as HTMLInputElement).value || "";

    console.log("inputSearch:", value);
    const reg = /^aleo[A-Za-z0-9]{59}$/;
    if (reg.test(value)) {
      navigate(`/${value}`);
    } else {
      antdUtils.notification?.error({
        message: "error",
        description: t("error.aleoFormat"),
      });
    }
  };

  return (
    <Input
      id="inputSearch"
      type="search"
      autoComplete="off"
      placeholder={t("queryMiner.inputAddress")}
      allowClear
      onPressEnter={onSearch}
      style={{ borderRadius: "22px" }}
      suffix={
        <SearchOutlined
          style={{ color: "rgba(0,0,0,.45)" }}
          onClick={onSearch}
        />
      }
    />
  );
};

export default observer(Search);
