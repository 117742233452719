import { useEffect, useState } from "react";

import styles from "./style.module.scss";

import { formatTime } from "../utils/time";
import { observer } from "mobx-react";
import aleoService, { SpeedDTO } from "./service";
import { Line } from "@ant-design/plots";

import { useRequest } from "@/hooks/use-request";

interface PropsType {
  address?: any;
  onClose?: () => void;
}

const PowerChart: React.FC<PropsType> = ({ address }) => {
  const { runAsync: powerHistory } = useRequest(aleoService.powerHistory, {
    manual: true,
  });

  const [powerHistoryData, setPowerHistoryData] = useState<any[]>([]);
  useEffect(() => {
    const powerChart = async (address: string) => {
      const [error, powers] = await powerHistory(address);
      if (error) {
        return;
      }
      const powerData = powers.map((item: SpeedDTO) => ({
        timestamp: formatTime(item.timestamp * 1000),
        Hashrate: Number(item.value.toFixed(4)),
      }));
      setPowerHistoryData(powerData);
    };
    powerChart(address);
  }, [address]);

  const powerConfig = {
    data: powerHistoryData,
    xField: "timestamp",
    yField: "Hashrate",
    smooth: true,
    label: {},
    yAxis: {
      min: Math.min(
        ...powerHistoryData.map((item: any) => Number(item.Hashrate.toFixed(0)))
      ),
      max: Math.max(
        ...powerHistoryData.map((item: any) => Number(item.Hashrate.toFixed(0)))
      ), // min 和 max 设置Y轴最大值和最小值，然后自动分配
      // line: { style: { stroke: "#0A122E" } }, // 配上这条数据才会显示y轴 stroke等同css color
      // label: {
      //   formatter: (v: any) =>
      //     `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      //   style: {
      //     stroke: "#0A122E",
      //     fontSize: 12,
      //     fontWeight: 300,
      //     fontFamily: "Apercu",
      //   },
      // },
    },
    slider: {
      start: 0.1,
      end: 0.5,
    },
    xAxis: {
      tickCount: 10,
      // label: {
      //   formatter: (text: string, item: any, index: number) => {
      //     return text;
      //   },
      // },
    },

    point: {
      size: 4,
      shape: "breath-point",
    },
  };
  return (
    <>
      <div className={styles.chartWrapper}>
        <Line {...powerConfig} />
      </div>
    </>
  );
};

export default observer(PowerChart);
