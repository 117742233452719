import React from "react";
import ReactDOM from "react-dom/client"; // 修改后的引入路径
import NProgress from "nprogress";
import App from "./App";
// import "@/assets/styles/app.scss";

import "virtual:windi.css";
import "nprogress/nprogress.css";

import "./overwrite.css";

NProgress.configure({
  minimum: 0.3,
  easing: "ease",
  speed: 800,
  showSpinner: false,
  parent: "#root",
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
