import { t } from "@/utils/i18n";
import { useState, useEffect } from "react";
import { Typography, Table, TablePaginationConfig } from "antd";
import { formatTime } from "../utils/time";

import type { ColumnsType } from "antd/es/table";

import aleoService, { SolutionDTO } from "./service";
import { useRequest } from "@/hooks/use-request";

interface PropsType {
  address?: any;
  onClose?: () => void;
}

const SolutionPage: React.FC<PropsType> = ({ address }) => {
  const [dataSource, setDataSource] = useState<SolutionDTO[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const { loading, runAsync: solutionPageData } = useRequest(
    aleoService.solutionPageData,
    { manual: true }
  );
  const { Text } = Typography;
  const EllipsisMiddle: React.FC<{ suffixCount: number; children: string }> = ({
    suffixCount,
    children,
  }) => {
    children = children + "";
    const cut = children.length > suffixCount;

    const start = cut ? children.slice(0, suffixCount) : children;
    const suffix = cut ? "..." + children.slice(-suffixCount) : "";
    return (
      <Text
        style={{ maxWidth: "100%" }}
        ellipsis={{ suffix }}
        copyable={{ text: children, tooltips: children }}
      >
        {start}
      </Text>
    );
  };
  const getList = async () => {
    const { current, pageSize } = pagination || {};

    const [error, data] = await solutionPageData({
      current,
      pageSize,
      address,
    });
    if (!error) {
      setDataSource(
        data.results.map((item: any) => ({
          ...item,
        }))
      );
      setPagination((prev) => ({
        ...prev,
        total: data.total,
      }));
    }
  };
  const tabChangeHandle = (tablePagination: TablePaginationConfig) => {
    setPagination(tablePagination);
  };
  useEffect(() => {
    getList();
  }, [pagination.size, pagination.current]);

  const columns: ColumnsType<any> = [
    {
      title: "NO.",
      render: (_, __, index) => `${index + 1}`,
    },

    {
      title: t("Nonce"),
      dataIndex: "nonce",
      render: (nonce) => {
        return <EllipsisMiddle suffixCount={5}>{nonce}</EllipsisMiddle>;
      },
    },
    {
      title: t("Block Hash"),
      dataIndex: "block_hash",
      render: (block_hash) => {
        return <EllipsisMiddle suffixCount={12}>{block_hash}</EllipsisMiddle>;
      },
    },

    {
      title: t("Target"),
      dataIndex: "target",
      key: "target",
    },
    {
      title: t("Reward"),
      dataIndex: "reward",
    },
    {
      title: t("Height"),
      dataIndex: "height",
    },
    {
      title: t("Epoch"),
      dataIndex: "epoch",
    },
    {
      title: t("Time"),
      dataIndex: "timestamp",
      render: (timestamp) => formatTime(timestamp * 1000),
    },
    {
      title: t("Commitment"),
      dataIndex: "commitment",
      render: (commitment) => {
        return <EllipsisMiddle suffixCount={10}>{commitment}</EllipsisMiddle>;
      },
    },
  ];

  return (
    <div>
      <div className="mt-[16px] dark:bg-[rgb(33,41,70)] bg-white rounded-lg px-[12px]">
        <Table
          rowKey="nonce"
          loading={loading}
          scroll={{ x: true }}
          columns={columns}
          className="bg-transparent"
          dataSource={dataSource}
          onChange={tabChangeHandle}
        />
      </div>
    </div>
  );
};

export default SolutionPage;
