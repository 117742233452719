import Search from "./search";

const Index = () => {
  return (
    <div className="bg-primary flex justify-center items-center h-[100vh]">
      <div className="flex-[2.5] flex justify-center">
        <div className=" w-[30%] mt-[-12%] rounded-lg <lg:(w-[94%] mx-auto)">
          <Search></Search>
        </div>
      </div>
    </div>
  );
};

export default Index;
