import { Loading } from "@/components/loading";
import { FC, Suspense } from "react";

const Content: FC<any> = ({ children }) => {
  return (
    <div
      className="w-[100%]  !<lg:ml-[16px]"
      style={{
        borderRadius: "8px",
        minHeight: "calc(100vh - 80px)",
        transition: "all 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
        width: `calc(100vw - 20px)`,
      }}
    >
      <div className="m-0 rounded-md z-1 p-[0px]">
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </div>
    </div>
  );
};

export default Content;
