import request from "@/config/request";

export interface PageData<T> {
  results: T[];
  total: number;
  page: number;
  size: number;
}

export interface MachineDTO {
  machine_name: string;
  ip: string;
  mac: string;
  speed: number;
  push_time: number;
}

export interface SolutionDTO {
  nonce: string; // 2453283778641614300;
  commitment: string; //  "puzzle1quw7yc4awsgpz6sycxp05fl3kwrv9grjvhzn0t5c0jfpe3japqfnr8m2c29w97tr0z6jthq7nx7gqdqr9c4";
  target: number; // 60880550;
  reward: number; // 1295446;
  height: number; // 1239470;
  epoch: number; //3442;
  timestamp: number; //1706089804;
  block_hash: string; // "ab1pr07h07zmjad2p8n4v5uwpwf7ak6j094cs9lx6dq2agnnqw5ngxqjxxfgf";
}

export interface SpeedDTO {
  timestamp: number;
  value: number;
}

export interface AddressInfoDTO {
  address: string;
  credits: number;
  speed: SpeedDTO[];
  machines: MachineDTO[];
}

const apiUrl = "/api/v1";
const aleoService = {
  // machine
  machine: (address: string) => {
    return request.get<MachineDTO[]>(`${apiUrl}/machine?address=${address}`);
  },
  // power_history
  powerHistory: (address: string) => {
    return request.get<SpeedDTO[]>(
      `${apiUrl}/power_history?address=${address}`
    );
  },
  // solution
  solutionPageData: async ({
    current,
    pageSize,
    address,
  }: {
    current: number;
    pageSize: number;
    address: string;
  }) => {
    return request.get<PageData<SolutionDTO>>(
      `${apiUrl}/solution?page=${current}&size=${pageSize}&address=${address}`
    );
  },
  // reward_history
  rewardHistory: (address: string, fm: string) => {
    return request.get<any>(
      `${apiUrl}/reward_history?address=${address}&fm=${fm}`
    );
  },
  // all_data
  allData: (fm: string, to: string, step: string) => {
    return request.get<AddressInfoDTO[]>(
      `${apiUrl}/all_data?fm=${fm}&to=${to}&step=${step}`
    );
  },
  // address_all_data
  addressAllData: (address: string, fm: string, to: string, step: string) => {
    return request.get<AddressInfoDTO>(
      `${apiUrl}/all_data?address=${address}&fm=${fm}&to=${to}&step=${step}`
    );
  },
};

export default aleoService;
