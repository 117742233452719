import cn from "classnames";
import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { t } from "@/utils/i18n";

import { observer } from "mobx-react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Typography, Tabs, Statistic, Spin } from "antd";
import CountUp from "react-countup";

import aleoService, { SpeedDTO } from "./service";

import { formatTime } from "../utils/time";
import { Line } from "@ant-design/plots";

import Solution from "./solution";
import Machine from "./machine";
import PowerChart from "./power-chart";
import RewardChart from "./reward-chart";
import { useRequest } from "@/hooks/use-request";
import styles from "./style.module.scss";
import Search from "./search";
import logo_aleo from "@/assets/images/aleo.jpg";

const AddressSearch = () => {
  const { loading, runAsync: addressAllData } = useRequest(
    aleoService.addressAllData,
    {
      manual: true,
    }
  );

  const [tabs, setTabs] = useState<any[]>([]);
  const params = useParams();
  const navigate = useNavigate();

  const [powerHistoryData, setPowerHistoryData] = useState<any[]>([]);
  const [worker, setWorker] = useState<number>(0);
  const [hashrate, setHashrate] = useState<number>(0);
  const [credits, setCredits] = useState<number>(0);
  const formatter = (value: any) => <CountUp end={value} separator="," />;

  useEffect(() => {
    const loadAddressData = async (address: any) => {
      const [error, data] = await addressAllData(address, "15m", "now", "1m");
      if (error) {
        return;
      }

      const powerData = (data.speed || []).map((item: SpeedDTO) => ({
        timestamp: formatTime(item.timestamp * 1000),
        Hashrate: Number(item.value.toFixed(4)),
      }));
      setPowerHistoryData(powerData);
      setCredits(data.credits);
      const machines = data.machines || [];

      let work = 0;
      let speed = 0;
      for (const imachine of machines) {
        const online =
          imachine.push_time * 1000 > new Date().getTime() - 1 * 60 * 60 * 1000;
        if (online) {
          work += 1;
          speed += imachine.speed;
        }
      }
      setWorker(work);
      setHashrate(speed);
    };

    if (params?.address) {
      const timestamp = new Date().getTime();
      const addr = params.address;
      const items = [
        {
          label: "Solution",
          key: `Solution_${timestamp}`,
          children: <Solution address={addr} />,
        },
        {
          label: "Machine",
          key: `Machine_${timestamp}`,
          children: <Machine address={addr} />,
        },
        {
          label: "Hashrate",
          key: `Hashrate_${timestamp}`,
          children: <PowerChart address={addr} />,
        },
        {
          label: "Reward",
          key: `Reward_${timestamp}`,
          children: <RewardChart address={addr} />,
        },
      ];
      setTabs(items);
      loadAddressData(addr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.address]);

  const powerConfig = {
    data: powerHistoryData,
    xField: "timestamp",
    yField: "Hashrate",
    smooth: true,
    label: {},
    yAxis: {
      min: Math.min(
        ...powerHistoryData.map((item: any) => Number(item.Hashrate.toFixed(0)))
      ),
      max: Math.max(
        ...powerHistoryData.map((item: any) => Number(item.Hashrate.toFixed(0)))
      ), // min 和 max 设置Y轴最大值和最小值，然后自动分配
      // line: { style: { stroke: "#0A122E" } }, // 配上这条数据才会显示y轴 stroke等同css color
      // label: {
      //   formatter: (v: any) =>
      //     `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      //   style: {
      //     stroke: "#0A122E",
      //     fontSize: 12,
      //     fontWeight: 300,
      //     fontFamily: "Apercu",
      //   },
      // },
    },
    slider: {
      start: 0.1,
      end: 0.5,
    },
    xAxis: {
      tickCount: 10,
      // label: {
      //   formatter: (text: string, item: any, index: number) => {
      //     return text;
      //   },
      // },
    },

    point: {
      size: 4,
      shape: "breath-point",
    },
  };
  return (
    <>
      <Spin spinning={loading} fullscreen />
      <div className={cn([styles.detail])}>
        <div className={styles.search_head}>
          <div
            className={cn([styles.goBack, "pointer"])}
            onClick={() => navigate("/")}
          >
            <ArrowLeftOutlined />
            {t("noPage.back")}
          </div>
          <div className={`${styles.inputWrapper}  `}>
            <Search />
          </div>
        </div>
        <div className={styles.head}>
          <img className={cn([styles.aleo])} src={logo_aleo} alt="aleo" />
          <div className="description-light">{t("queryMiner.aleoAddress")}</div>
          <Typography.Title copyable level={5}>
            {params?.address}
          </Typography.Title>
          <div className={styles.statistics}>
            <dl>
              <dd>
                <Statistic
                  title={`${t("queryMiner.hashrate")} (P/s)`}
                  value={hashrate}
                  precision={4}
                  formatter={formatter}
                />
              </dd>
            </dl>
            <dl>
              <dt>
                <Statistic
                  title={`${t("management.machine.online")}`}
                  value={worker}
                  precision={0}
                  formatter={formatter}
                />
              </dt>
            </dl>
            <dl>
              <dt>
                <Statistic
                  title={`${t("management.machine.credits")}`}
                  value={credits}
                  precision={0}
                  formatter={formatter}
                />
              </dt>
            </dl>
          </div>
        </div>
        <div className={styles.chartWrapper}>
          <h3>{t("queryMiner.hashrate")}</h3>
          <Line {...powerConfig} />
        </div>

        <div className={styles.tableWrapper}>
          <h3>{t("queryMiner.minerData")}</h3>
          <Tabs items={tabs} />
        </div>
      </div>
    </>
  );
};

export default observer(AddressSearch);
