import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";

interface State {
  darkMode: boolean;
  collapsed: boolean;
  lang: string;
  token: string;
  refreshToken: string;
  withWallet: boolean;
}

interface Action {
  setDarkMode: (darkMode: State["darkMode"]) => void;
  setCollapsed: (collapsed: State["collapsed"]) => void;
  setLang: (lang: State["lang"]) => void;
  setToken: (token: State["token"]) => void;
  setRefreshToken: (refreshToken: State["refreshToken"]) => void;
  setWithWallet: (withWallet: State["withWallet"]) => void;
}

export const useGlobalStore = create<State & Action>()(
    devtools(
        persist(
            (set) => {
              return {
                darkMode: false,
                collapsed: false,
                lang: "zh",
                token: "",
                refreshToken: "",
                withWallet: false,
                setDarkMode: (darkMode: State["darkMode"]) =>
                    set({
                      darkMode,
                    }),
                setCollapsed: (collapsed: State["collapsed"]) =>
                    set({
                      collapsed,
                    }),
                setLang: (lang: State["lang"]) =>
                    set({
                      lang,
                    }),
                setToken: (token: State["token"]) =>
                    set({
                      token,
                    }),
                setRefreshToken: (refreshToken: State["refreshToken"]) =>
                    set({
                      refreshToken,
                    }),
                setWithWallet: (withWallet: State["withWallet"]) =>
                    set({
                      withWallet,
                    }),
              };
            },
            {
              name: "globalStore",
              storage: createJSONStorage(() => localStorage),
            }
        ),
        { name: "globalStore" }
    )
);
