import { Outlet, useLocation } from "react-router-dom";
import { useGlobalStore } from "@/stores/global";
import { useEffect, useState } from "react";
import GloablLoading from "@/components/global-loading";

import { replaceRoutes, router } from "@/router";
import Result404 from "@/404";

import "./index.css";
import Content from "./content";

const BasicLayout: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    replaceRoutes("*", [
      {
        id: "*",
        path: "*",
        Component: Result404,
        handle: {
          path: "404",
          name: "404",
        },
      },
    ]);

    setLoading(false);
    router.navigate(`${location.pathname}${location.search}`, {
      replace: true,
    });
  }, []);

  useEffect(() => {
    function storageChange(e: StorageEvent) {
      if (e.key === useGlobalStore.persist.getOptions().name) {
        useGlobalStore.persist.rehydrate();
      }
    }

    window.addEventListener<"storage">("storage", storageChange);

    return () => {
      window.removeEventListener<"storage">("storage", storageChange);
    };
  }, []);

  if (loading) {
    return <GloablLoading />;
  }

  return (
    <Content>
      <Outlet />
    </Content>
  );
};

export default BasicLayout;
