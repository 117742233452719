import { useEffect, useState } from "react";

import styles from "./style.module.scss";

import { observer } from "mobx-react";
import aleoService from "./service";
import { Line } from "@ant-design/plots";

import { useRequest } from "@/hooks/use-request";

interface PropsType {
  address?: any;
  onClose?: () => void;
}

const RewardChart: React.FC<PropsType> = ({ address }) => {
  const { runAsync: rewardHistory } = useRequest(aleoService.rewardHistory, {
    manual: true,
  });

  const [rewardHistoryData, setRewardHistoryData] = useState<any[]>([]);
  useEffect(() => {
    const powerChart = async (address: string) => {
      const [error, reward] = await rewardHistory(address, "30d");
      if (error) {
        return;
      }

      const rewardData = Object.keys(reward).map((key, index) => {
        return {
          timestamp: key,
          Reward: Number(reward[key]),
          index,
        };
      });
      setRewardHistoryData(rewardData);
    };
    powerChart(address);
  }, [address]);

  const rewardConfig = {
    data: rewardHistoryData,
    xField: "timestamp",
    yField: "Reward",
    smooth: true,
    label: {},
    yAxis: {
      min: Math.min(
        ...rewardHistoryData.map((item: any) => Number(item.Reward.toFixed(0)))
      ),
      max: Math.max(
        ...rewardHistoryData.map((item: any) => Number(item.Reward.toFixed(0)))
      ), // min 和 max 设置Y轴最大值和最小值，然后自动分配
      // line: { style: { stroke: "#0A122E" } }, // 配上这条数据才会显示y轴 stroke等同css color
      // label: {
      //   formatter: (v: any) =>
      //     `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      //   style: {
      //     stroke: "#0A122E",
      //     fontSize: 12,
      //     fontWeight: 300,
      //     fontFamily: "Apercu",
      //   },
      // },
    },
    slider: {
      start: 0.1,
      end: 0.5,
    },
    xAxis: {
      tickCount: 10,
      // label: {
      //   formatter: (text: string, item: any, index: number) => {
      //     return text;
      //   },
      // },
    },
    point: {
      size: 4,
      shape: "breath-point",
    },
  };
  return (
    <>
      <div className={styles.chartWrapper}>
        <Line {...rewardConfig} />
      </div>
    </>
  );
};

export default observer(RewardChart);
